import React, { useState } from "react"
import styled from "styled-components"
import { IoCloseSharp, IoCheckmarkSharp } from "react-icons/io5"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"
import { Column, Row } from "@react-tiny-grid/core"

const TableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 20px;

  & > div {
    background-color: #fff;
    width: 100%;
    min-width: 900px;
    border: 2px solid #1c365e;
    border-radius: 20px;
    overflow: hidden;
  }
`

const Table = styled.table`
  width: 100%;
  min-width: 900px;
  color: #1c365e;
  border-radius: 20px;
  overflow: hidden;
  height: 1px;

  & th {
    padding: 10px;
    font-weight: 700;
    height: 100%;
  }

  & td {
    padding: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 0.8rem;
    width: 14% !important;
    height: 100%;
  }

  &.border,
  &.border th,
  &.border td {
    padding: 10px 15px;
    border: 1px solid #1c365e;
    border-collapse: collapse;
  }

  &.border tr th:first-child,
  &.border tr td:first-child {
    border-left: none;
  }

  &.border tr th:last-child,
  &.border tr td:last-child {
    border-right: none;
  }

  &.border tr:first-child th {
    border-top: none;
  }

  &.border tr:last-child th,
  &.border tr:last-child th {
    border-bottom: none;
  }

  & tr:first-child {
    width: 35% !important;
  }

  & .hidden {
    visibility: hidden;
  }
`

const ShadowBox = styled.div`
  width: 100%;
  padding: 20px 10px;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &.h100 {
    height: 100%;
  }

  &.p0 {
    padding: 0 !important;
  }

  &.p0 * {
    width: 100%;
  }
`

const BorderBox = styled.div`
  border: 2px solid #1c365e;
  padding: 10px;

  &.rounded {
    border-radius: 7px;
  }
`

const Button = styled(Link)`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

const SpanButton = styled.span`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

const ToggleContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: "row";

  & > h4 {
    margin: 10px;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

const Toggle = styled.div`
  padding: 10px 40px;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? "#1c365e" : "#F9C712")};
  color: ${({ active }) => (active ? "white" : "#1c365e")};
  position: relative;
  z-index: ${({ active }) => (active ? "1" : "0")};
  font-weight: bold;
  cursor: pointer;

  &.right {
    margin-left: -30px;
  }
`

const otherFeatures = [
  ["Case Manager", true, true, true, true, true],
  ["A4 Printable Screenshots", true, true, true, true, true],
  ["Virtual Machine Support", true, true, true, true, true],
  ["Audio/Video Recording", true, true, true, true, true],
  ["Network Packet Capture", true, true, true, true, true],
  ["SSL/TLS Keys Export", true, true, true, true, true],
  ["Chain of Custody", true, true, true, true, true],
  ["Page Source Download", true, true, true, true, true],
  ["User Activity Audit Logs", true, true, true, true, true],
  ["Enhanced Video User Clicks", true, true, true, true, true],
  ["Source Code Extraction", true, true, true, true, true],
  ["Save as Web Page Archive", true, true, true, true, true],
  ["Web/Socks Proxy Support", true, true, true, true, true],
]

const Cross = () => <IoCloseSharp color="red" fontSize="1.5rem" />
const Tick = () => <IoCheckmarkSharp color="green" fontSize="1.5rem" />

const PricingPage = () => {
  const [yearly, setYearly] = useState(true)

  const toggle = () => {
    setYearly(!yearly)
  }

  return (
    <Layout>
      <Seo title="Pricing" />
      <h1 className="head">Web Forensics should be easy</h1>
      <br />
      <h1 className="sub-head">... and always fairly priced!</h1>
      <br />
      <br />

      <ToggleContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Toggle active={yearly} onClick={toggle}>
            Yearly
          </Toggle>
          <Toggle className="right" active={!yearly} onClick={toggle}>
            Monthly
          </Toggle>
        </div>

        <h4 className="text-primary">2 months free with yearly plan</h4>
      </ToggleContainer>

      <TableContainer>
        <Table>
          <tr>
            <th colSpan={5}>&nbsp;</th>
            <td rowSpan={3}>
              <ShadowBox className="h100 p0">
                <h4
                  style={{
                    background: "#00CC07",
                    padding: "20px 10px 10px",
                    marginTop: "-10px",
                    width: "110%",
                  }}
                >
                  Best Offer
                </h4>
                <h3
                  style={{
                    borderTop: "2px solid #1c365e",
                    borderBottom: "2px solid #1c365e",
                    padding: 10,
                  }}
                >
                  HYBRID
                </h3>
                <div
                  style={{
                    padding: "20px 10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    height: "100%",
                  }}
                >
                  <h3>Omniquire</h3>
                  <h6 class="hidden">.</h6>
                  <h3>-{/* {yearly ? "$$$$" : "$$$"} */}</h3>
                  <h4 class="hidden">/{yearly ? "year" : "month"}</h4>
                  <h6 class="hidden">.</h6>
                  <Button to="#" background="#1c365e" color="#F9C712">
                    Coming Soon
                  </Button>
                </div>
              </ShadowBox>
            </td>
          </tr>

          <tr>
            <th>&nbsp;</th>
            <td colSpan={2}>
              <BorderBox className="rounded">DESKTOP APPLICATION</BorderBox>
            </td>
            <td colSpan={2}>
              <BorderBox className="rounded">CLOUD APPLICATION</BorderBox>
            </td>
          </tr>

          <tr>
            <th>
              <h1>Features by Plan</h1>
            </th>
            <td>
              <ShadowBox className="h100">
                <h3>Community</h3>
                <h6 class="hidden">.</h6>
                <h3>Free</h3>
                <h4 class="hidden">.</h4>
                <h6 class="hidden">.</h6>
                <Button
                  to="https://eviquire.com/download/"
                  background="#1c365e"
                >
                  Download
                </Button>
              </ShadowBox>
            </td>
            <td>
              <ShadowBox className="h100">
                <h3>Expert</h3>
                <h6 class="hidden">.</h6>
                <h3>{yearly ? "$800" : "$80"}</h3>
                <h4>/{yearly ? "year" : "month"}</h4>
                <h6 class="hidden">.</h6>
                <Button to={process.env.GATSBY_MY_PORTAL} background="#1c365e">
                  Get Started
                </Button>
              </ShadowBox>
            </td>
            <td>
              <ShadowBox className="h100">
                <h3>Essential</h3>
                <h6 class="hidden">.</h6>
                <h3>-{/* {yearly ? "$$$" : "$$"} */}</h3>
                <h4 class="hidden">/{yearly ? "year" : "month"}</h4>
                <h6 class="hidden">.</h6>
                <Button to="#" background="#1c365e">
                  Coming Soon
                </Button>
              </ShadowBox>
            </td>
            <td>
              <ShadowBox className="h100">
                <h3>Professional</h3>
                <h6 class="hidden">.</h6>
                <h3>-{/* {yearly ? "$$$$" : "$$$"} */}</h3>
                <h4 class="hidden">/{yearly ? "year" : "month"}</h4>
                <h6 class="hidden">.</h6>
                <Button to="#" background="#1c365e">
                  Coming Soon
                </Button>
              </ShadowBox>
            </td>
          </tr>
        </Table>
      </TableContainer>

      <TableContainer>
        <div>
          <Table className="border">
            <tr>
              <th style={{ textAlign: "left" }} colSpan={6}>
                Core Features
              </th>
            </tr>

            <tr>
              <th>&nbsp;</th>
              <th>Community</th>
              <th>Expert</th>
              <th>Essential</th>
              <th>Professional</th>
              <th>Omniquire</th>
            </tr>

            <tr>
              <th>Platforms</th>
              <td colSpan={2}>Windows - Linux - MacOs - VM</td>
              <td colSpan={2}>HTML5 Web Browser</td>
              <td>Desktop+Web</td>
            </tr>

            <tr>
              <th>License Type</th>
              <td colSpan={2}>Floating Subscription</td>
              <td colSpan={2}>Floating Subscription</td>
              <td>Subscription</td>
            </tr>

            <tr>
              <th>Cases/Acquisitions/Evidence</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>

            <tr>
              <th>Acquisition Max Duration</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>1 Hour</td>
              <td>1 Hour</td>
              <td>Unlimited / 1 Hour</td>
            </tr>

            <tr>
              <th>Total Acquisition Hours / Month</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>1 Hour</td>
              <td>25 Hour</td>
              <td>Unlimited / 30 Hour</td>
            </tr>

            <tr>
              <th>Multi Blockchain Timestamp</th>
              <td>
                <Cross />
              </td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>

            <tr>
              <th>Forensic Reports</th>
              <td>
                <Cross />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
            </tr>

            <tr>
              <th>Multi Type Evidence</th>
              <td>Only Screenshots</td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
            </tr>

            <tr>
              <th>Evidence PageShot Type</th>
              <td>
                <Cross />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
            </tr>

            <tr>
              <th>Smart Page Auto-scroll</th>
              <td>
                <Cross />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
            </tr>

            <tr>
              <th>Comments - Replies Auto-Expansion</th>
              <td>
                <Cross />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
              <td>
                <Tick />
              </td>
            </tr>
          </Table>
        </div>
      </TableContainer>

      <ShadowBox style={{ marginBottom: "20px", padding: "5px 25px" }}>
        <Row>
          <Column widths={[8]}>
            <h5 className="text-primary text-center">
              If you are LEA, Gov, Mil and you require our{" "}
              <h2 style={{ color: "#00CC07", display: "inline" }}>
                On-Premise
              </h2>{" "}
              SaaS Web Forensic Solution OR the default limits feel tight on you
              and you need a{" "}
              <h2 style={{ color: "#00CC07", display: "inline" }}>
                Custom Plan.
              </h2>
            </h5>
          </Column>

          <Column widths={[4]}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <SpanButton
                background="#F9C712"
                color="#1c365e"
                onClick={() => {
                  window.HelpWidget("open")
                }}
              >
                Contact Sales
              </SpanButton>
            </div>
          </Column>
        </Row>
      </ShadowBox>

      <TableContainer>
        <div>
          <Table className="border">
            <tr>
              <th style={{ textAlign: "left" }} colSpan={6}>
                Other Features
              </th>
            </tr>

            {otherFeatures.map(feature => (
              <tr>
                <th>{feature}</th>
                <td>{(feature[1] && <Tick />) || <Cross />}</td>
                <td>{(feature[2] && <Tick />) || <Cross />}</td>
                <td>{(feature[3] && <Tick />) || <Cross />}</td>
                <td>{(feature[4] && <Tick />) || <Cross />}</td>
                <td>{(feature[5] && <Tick />) || <Cross />}</td>
              </tr>
            ))}
          </Table>
        </div>
      </TableContainer>
    </Layout>
  )
}

export default PricingPage
